<div [ngClass]="{ 'has-edit-button': page?.isPreview() }" *ngIf="document">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document" [relative]="false" root="">
  </ng-container>
  <ng-container *ngIf="data?.contentType === 'Home Page View' else calculatorView">
    <section class="FT-faq" [ngClass]="isDistributor? 'bg-light-blue':'bg-gradient'">
      <div class="container">
        <div class="text-center">
          <div class="FT-title" [ngClass]="isDistributor? 'text-black':''">{{ faqTitle }}</div>
          <div class="text-big text-white" *ngIf="!isDistributor">
            {{ faqDesc }}
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="commonCode"></ng-container>
        <div class="text-center">
          <a href="{{viewAllURL}}" target="_self"
             [ngClass]="{ 'footer-links-CTA': !isDistributor }"><span
              class="login-dark2 aboutlinks">{{ viewAllText }}</span><i
              class="bi bi-chevron-right ms-3"></i></a>
        </div>
      </div>
    </section>
  </ng-container>
  <!-- start section calc faq-->
  <ng-template #calculatorView>
    <section class="FT-faq FT-calc-faq mb-6 pb-0">
      <div class="container">
        <div class="FT-title-small mb-sm-4 mb-3"><h2 style="font-size: 1.625rem;">{{ faqTitle }}</h2></div>
        <ng-container [ngTemplateOutlet]="commonCode"></ng-container>
      </div>
    </section>
  </ng-template>
  <ng-template #commonCode>
    <div class="faq-box">
      <div class="accordion" id="accordionExample">
        <ng-container *ngFor="let item of content; let i = index">
          <ng-container *ngIf="i < 30">
            <div class="accordion-item">
              <h3 style="font-size: 1.125rem;" class="accordion-header" [id]="'heading' + i">
                <button class="accordion-button" [ngClass]="{
                    collapsed:
                      currentPath !== item?.title.toLowerCase() && !(openFirstAccordion && i === 0) && !(hashNavigation === 'faq' + i)} "
                        type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faq' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'faq' + i">
                  {{ getAccordionTitle(item?.title, "faq" + i) }}
                </button>
              </h3>
              <div [id]="'faq' + i" class="accordion-collapse collapse" [ngClass]="{
                    show:
                      currentPath === item?.title.toLowerCase() ||
                      hashNavigation === 'faq' + i ||
                      (openFirstAccordion && i === 0)
                  }" [attr.aria-labelledby]="'heading' + i"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body"
                     [innerHTML]="item?.content?.content?.value">
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
