<div *ngIf="classVal === 'invest'">
  <h3 *ngIf="showHeading" class="text-center">{{ "ftiGeneric.lead-form-title" | translate }}</h3>
</div>
<div class="modal-header" *ngIf="classVal === 'contact'">
  <div class="modal-title mx-auto" id="callBackLabel1">{{ "ftiGeneric.lead-form-title" | translate }}</div>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="clear()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <!-- start -->
  <ng-container *ngIf="!otpRequired">
    <form [formGroup]="callbackfinancialForm">

      <div class="text-center" *ngIf="showHeading">
        <div class="form-check form-check-inline mb-4">
          <label id="radio1" class="form-check-label fontResize" for="inlineRadio1">New Investor</label>
          <input class="form-check-input" (click)="newInvestorInput()" type="radio" id="inlineRadio1" [value]="0"
                 [(ngModel)]="otpChecked" [ngModelOptions]="{standalone: true}" name="otpChecked"
                 [checked]="otpChecked === 0" aria-labelledby="radio1">
        </div>

        <div class="form-check form-check-inline mb-4">
          <label id="radio2" class="form-check-label fontResize" for="inlineRadio2">Existing Investor</label>
          <input class="form-check-input" (click)="existingInvestorInput()" type="radio" id="inlineRadio2" [value]="1"
                 [(ngModel)]="otpChecked" name="otpChecked" [ngModelOptions]="{standalone: true}"
                 [checked]="otpChecked === 1" aria-labelledby="radio2">
        </div>
      </div>

      <div *ngIf="newInvestor">
        <div class="row g-3">
          <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
            <input type="text" id="communication_Name" class="form-control" formControlName="name"
                   placeholder="{{namePlaceholder}} *">
            <div
                *ngIf="callbackfinancialForm?.get('name')?.hasError('required') && !callbackfinancialForm?.get('name')?.value && callbackfinancialForm?.get('name')?.touched">
              <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-name" | translate }}</span>
            </div>
            <div
                *ngIf="callbackfinancialForm?.get('name')?.hasError('pattern') && !callbackfinancialForm?.get('name')?.hasError('required') && callbackfinancialForm?.get('name')?.touched">
              <span style="color: #F04438;" class="error-msg">{{ "ftiFeedback.form-name-validator" | translate }}</span>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
            <input type="text" id="communicationPhoneNumber" class="form-control" formControlName="phone"
                   placeholder="{{phonePlaceholder}} *" minlength="10" maxlength="10">
            <div
                *ngIf="callbackfinancialForm?.get('phone')?.hasError('required') && !callbackfinancialForm?.get('phone')?.value && callbackfinancialForm?.get('phone')?.touched">
              <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-phone" | translate }}</span>
            </div>
            <div class="communicationPhoneNumber-error"
                 *ngIf="(callbackfinancialForm.get('phone').value && (callbackfinancialForm.get('phone').value).length <=9 && callbackfinancialForm?.get('phone')?.touched)||( callbackfinancialForm?.get('phone')?.hasError('pattern') && !callbackfinancialForm?.get('phone')?.hasError('required') && callbackfinancialForm?.get('phone')?.touched)">
              <span style="color: #F04438;" class="error-msg">{{
                      "ftiGeneric.lead-form-phone-invalid" |
                          translate
                }}</span>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
            <input type="email" id="communication_Email" class="form-control" formControlName="email"
                   placeholder="{{emailPlaceholder}} *">
            <div
                *ngIf="callbackfinancialForm?.get('email')?.hasError('required') && !callbackfinancialForm?.get('email')?.value && callbackfinancialForm?.get('email')?.touched">
              <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-email" | translate }}</span>
            </div>
            <div class="communicationEmail-error"
                 *ngIf="callbackfinancialForm?.get('email')?.hasError('pattern') && !callbackfinancialForm?.get('email')?.hasError('required') && callbackfinancialForm?.get('email')?.touched">
              <span style="color: #F04438;" class="error-msg">{{
                      "ftiGeneric.lead-form-email-invalid" |
                          translate
                }}</span>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
            <select id="city" class="form-select" (click)="loadOptions()" aria-label="Query list"
                    formControlName="query" style="color:#6d7072">
              <option value="" selected disabled>{{ "ftiGeneric.query-label" | translate }}</option>
              <ng-container *ngIf="loadDD">
                <option value="{{ query?.name }}" *ngFor="let query of queryList">
                  {{ query.name }}
                </option>
              </ng-container>
            </select>
            <div
                *ngIf="callbackfinancialForm?.get('query')?.hasError('required') && !callbackfinancialForm?.get('query')?.value && callbackfinancialForm?.get('query')?.touched">
              <span style="color: #F04438;" class="error-msg">{{ "ftiGeneric.lead-form-query" | translate }}</span>
            </div>
          </div>
        </div>
        <div class="form-check my-3">
          <input class="form-check-input" type="checkbox" value="" (change)="checkboxCondition($event)"
                 id="flexCheckDefault" aria-labelledby="checkPerm">
          <label class="form-check-label" id="checkPerm" for="flexCheckDefault">
            {{ "ftiGeneric.lead-form-permission" | translate }}
          </label>
        </div>
        <div>
          <p>{{ "ftiGeneric.lead-form-text1" | translate }}
            <a class="forCallBackLable" href="{{ 'ftiGeneric.lead-form-link1' | translate }}">{{
                    "ftiGeneric.lead-form-link1-label" |
                        translate
              }}</a>
            <span class="d-md-inline-block">&nbsp;{{ "ftiGeneric.lead-form-and-label" | translate }}
              <a class="forCallBackLable" href="{{ 'ftiGeneric.lead-form-link2' | translate }}">
                {{ "ftiGeneric.lead-form-link2-label" | translate }}</a>
            </span>
          </p>
        </div>
        <div class="row gx-3 location-custom-btn">
          <div class="col-12">
            <button id="continue-btn" type="submit" class="btn btn-primary w-100"
                    [disabled]="!callbackfinancialForm.valid || !filedDefaullt" (click)="submitInvestForm()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ "ftiGeneric.submit-btn-label" | translate }}
            </button>
          </div>
        </div>
        <div class="row gx-2 d-md-none" style="gap:10px;">
          <div class="col-12">
          </div>
        </div>
      </div>

      <!-- ---------------- -->
      <form [formGroup]="investNowForm1" *ngIf="!formRequired">
        <div *ngIf="existingInvestor">
          <div class="row">
            <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
              <input type="text" class="form-control" [ngClass]="{'input-box':isCallback}"
                     formControlName="existingInvestorname" placeholder="Your name">
              <div
                  *ngIf="investNowForm1?.get('existingInvestorname')?.hasError('required') && !investNowForm1?.get('existingInvestorname')?.value && investNowForm1?.get('existingInvestorname')?.touched">
                <span style="color: #F04438;" class="error-msg fontResize">{{
                        "ftiGeneric.lead-form-name" | translate
                  }}</span>
              </div>
              <div
                  *ngIf="investNowForm1?.get('existingInvestorname')?.hasError('pattern') && !investNowForm1?.get('existingInvestorname')?.hasError('required') && investNowForm1?.get('existingInvestorname')?.touched">
                <span style="color: #F04438;" class="error-msg">{{
                        "ftiFeedback.form-name-validator" | translate
                  }}</span>
              </div>
            </div>
            <div class="form-group" [ngClass]="{'col-12': isSmall, 'col-6 mb-3': !isSmall}">
              <input type="text" (keydown)="blockAlphabetsAndSpecialChars($event)" class="form-control"
                     formControlName="existingInvestorphone" placeholder="Mobile Number" maxlength="10">
              <div
                  *ngIf="investNowForm1?.get('existingInvestorphone')?.hasError('required') && !investNowForm1?.get('existingInvestorphone')?.value && investNowForm1?.get('existingInvestorphone')?.touched">
                <span style="color: #F04438;" class="error-msg fontResize">{{
                        "ftiGeneric.lead-form-phone" | translate
                  }}</span>
              </div>
              <div class="communicationPhoneNumber-error"
                   *ngIf="(investNowForm1.get('existingInvestorphone').value && (investNowForm1.get('existingInvestorphone').value).length <=9 && investNowForm1?.get('existingInvestorphone')?.touched)||( investNowForm1?.get('existingInvestorphone')?.hasError('pattern') && !investNowForm1?.get('existingInvestorphone')?.hasError('required') && investNowForm1?.get('existingInvestorphone')?.touched)">
                <span style="color: #F04438;" class="error-msg">{{
                        "ftiGeneric.lead-form-phone-invalid" |
                            translate
                  }}</span>
              </div>
            </div>

            <div class="container-fluid py-3">
              <form #form="ngForm">
                <div class="row mt-3">
                  <div class="col-sm-12 mb-2">
                    <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="captchaResponse" required
                     [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                     (resolved)="onCaptchaResolved($event)"></re-captcha>
                    <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                      <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-check mb-3" style="text-align: left; padding-left: 2.1rem;">
              <span class="form-check-label fontResize">
                {{ "ftiGeneric.lead-form-text1" | translate }} <a href="{{ 'ftiGeneric.lead-form-link1' | translate }}" class="forCallBackLable fontResize">{{
                      "ftiGeneric.lead-form-link1-label" |
                          translate
                }}</a>&nbsp;<span>{{ "ftiGeneric.lead-form-and-label" | translate }}&nbsp;<a
                  class="forCallBackLable fontResize" href="{{ 'ftiGeneric.lead-form-link2' | translate }}"> {{
                      "ftiGeneric.lead-form-link2-label" | translate
                }}</a></span>
              </span>
            </div>
            <div class="row gx-3">
              <div class="col-12">
                <button class="btn btn-primary w-100 fontResize" [disabled]="!investNowForm1.valid || !captchaResolved"
                        (click)="moveTo1()"> {{ "ftiGeneric.submit-btn-label" | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </form>

    </form>

    <div class="container row gx-3 text-center form-success-msg" *ngIf="formSubmitted">
      <div class="row justify-content-end m-0" style="cursor:pointer;" (click)="close()"><img
          src="assets/images/home/close-form.svg" style="height:3rem;width:3rem;"></div>
      <img alt="image" src="assets/images/general/check_circle.svg" style="height:3rem;" class="checked-img mt-4 mb-2">
      <span style="font-size:1.125rem;" class="success-msg"><strong>&nbsp; {{ successMsgInvest }}</strong></span>
      <!-- [innerHTML]="successMsgInvest" -->
    </div>

  </ng-container>
  <ng-container *ngIf="otpRequired">
    <form [formGroup]="otpsubmitForm">
      <div class="container row gx-3 text-center" *ngIf="!otpSubmitted">
        <span class="mb-4" style="color:#8189a2" [innerHTML]="otpMessage"></span>
        <div class="container inputfield">
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber0" #locotpNumber0
                 [(ngModel)]="otpNumber[0]" (keyup)="otpValidation($event, locotpNumber1 , 1 , locotpNumber0)"  class="input" autofocus/>
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber1" #locotpNumber1
                 [(ngModel)]="otpNumber[1]" (keyup)="otpValidation($event,locotpNumber2 , 2 ,locotpNumber0)" class="input"/>
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber2" #locotpNumber2
                 [(ngModel)]="otpNumber[2]" (keyup)="otpValidation($event,locotpNumber3 , 3 , locotpNumber1)" class="input"/>
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber3" #locotpNumber3
                 [(ngModel)]="otpNumber[3]" (keyup)="otpValidation($event,locotpNumber4 , 4 , locotpNumber2)" class="input"/>
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber4" #locotpNumber4
                 [(ngModel)]="otpNumber[4]" (keyup)="otpValidation($event,locotpNumber5 , 5 , locotpNumber3)" class="input"/>
          <input type="password" pattern="[0-9]+" maxlength="1" formControlName="otpNumber5" #locotpNumber5
                 [(ngModel)]="otpNumber[5]" (keyup)="otpValidation($event,locationotpsubmit, 6 , locotpNumber4)" class="input"/>
        </div>
        <div class="row container m-auto mt-1 justify-content-center" style="color: green;" *ngIf="OTPresent">
          {{ "ftiGeneric.lead-form-otp-resent" | translate }}
        </div>
        <div class="d-flex flex-row justify-content-center mt-2">
          <ng-container *ngIf="remainingTime > 0; else resendOTP">
            {{ "ftiGeneric.lead-form-text-did-not-receive" | translate }}
            <div style="color:#3769ff"><span class="adv-otp-timer" id="adv-otp-timer">&nbsp;</span>Active in 0:{{
                    remainingTime
              }}
            </div>
          </ng-container>
          <!-- [innerHTML]="timerOTP" -->
          <ng-template #resendOTP>
            {{ "ftiGeneric.lead-form-text-did-not-receive" | translate }}
            <div class="adv-otp-timer" (click)="resetCountdown()"><span>&nbsp;</span>
              {{
                    "ftiGeneric.lead-form-text-otp-resend" | translate
              }}
            </div>
          </ng-template>
        </div>
        <div class="fleadgen-otp-message field-error">
        </div>
        <div class="fleadgen_OTP-error field-error">
          &nbsp;
        </div>
        <div class="row gx-3 callBack-footer mt-4">
          <div class="col-12">
            <button #locationotpsubmit class="btn btn-primary" style="width:90%; margin-left: 32px;"
                    [disabled]="submitnotdisabled" (click)="submitButton()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ "ftiGeneric.submit-btn-label" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="container row gx-3 text-center form-success-msg" *ngIf="otpSubmitted">
      <div class="row justify-content-end m-0" style="cursor:pointer;" (click)="clear()"><img
          src="assets/images/home/close-form.svg" style="height:3rem;width:3rem;"></div>
      <img alt="image" src="assets/images/general/check_circle.svg" style="height:3rem;" class="checked-img mt-4 mb-2">
      <span style="font-size:1.125rem;" class="success-msg"><strong>&nbsp; {{ successMsgInvest }}</strong></span>
    </div>
  </ng-container>
  <!-- Ends -->
</div>
