<form [formGroup]="topUpCalcForm" *ngIf="topUpCalcForm">
  <!-- 1. Earn -Amount & 2. Duration | Frequency -->
  <div class="form-group">
    <label id="iWantToInvest" for="customRange2" class="form-label">{{ "ftiCalc.calculator-want-invest" | translate }}

      <div class="selected-range">
        <span class="calc-input-prefix">{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}</span>
        <input aria-labelledby="iWantToInvest" id="customRange2" type="number" [attr.min]="getInputLimit(sliderKeyName.amount, false)"
               [attr.max]="getInputLimit(sliderKeyName.amount, true)" [(ngModel)]="topUpCalcForm.value.amount"
               formControlName="amount" (change)="onCalculatorChange($event, 'amount')"
               class="calc-input amount text-start">

      </div>
    </label>

    <div class="invest-options">
      <div class="form-check">
        <input aria-labelledby="iWantToInvestMonthly" formControlName="time" value="MONTHLY" class="form-check-input" type="radio" name="time"
               id="Monthly" (change)="onCalculatorChange($event, 'time')">
        <label id="iWantToInvestMonthly" class="form-check-label" for="Monthly">
          {{ "ftiCalc.calculator-lumpsum-text4" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input aria-labelledby="iWantToInvestQuarterly" formControlName="time" value="QUARTERLY" class="form-check-input" type="radio" name="time"
               id="Quarterly" (change)="onCalculatorChange($event, 'time')">
        <label id="iWantToInvestQuarterly" class="form-check-label" for="Quarterly">
          {{ "ftiCalc.calculator-quarterly-text1" | translate }}
        </label>
      </div>
    </div>

    <div class="range-slider">
      <input  aria-labelledby="iWantToInvest" type="range" [attr.min]="getInputLimit(sliderKeyName.amount, false)"
             [attr.max]="getInputLimit(sliderKeyName.amount, true)"
             [value]="topUpCalcForm.value.amount || getInputLimit(sliderKeyName.amount)" formControlName="amount"
             (change)="onCalculatorChange($event, 'amount')" class="custom-range"
             [style.background]="amountSliderStyle">
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
              "ftiCalc.calculator-500" | translate
        }}</span>
      <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
              "ftiCalc.calculator-10L" | translate
        }}</span>
    </div>
  </div>

  <!-- 3. & 4. Increase SIP by 3. % | 4. ₹ -->
  <div class="form-group ">
    <label id="increasePercentBy" for="customRange2" class="form-label">
      <span *ngIf="!showBytoggle"> {{ "ftiCalc.calculator-sip-topup-increase-amount" | translate }} </span>
      <span *ngIf="showBytoggle"> {{ "ftiCalc.calculator-sip-topup-increase-percent" | translate }} </span>
      <div class="d-flex">
        <div class="form-check form-switch">
          <input aria-labelledby="amountOrPercentagetoggle" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                 formControlName="isIncreaseByPercent" [(ngModel)]="topUpCalcForm.value.isIncreaseByPercent"
                 (change)="onCalculatorChange($event, 'isIncreaseByPercent')">
          <label id="amountOrPercentagetoggle" class="form-check-label" for="flexSwitchCheckChecked">
            <span class="absoluate-option price">{{
                    "ftiCalc.calculator-indian-rupee-symbol" | translate
              }}</span>
            <span class="absoluate-option percentage">{{
                    "ftiCalc.calculator-percentage-symbol" | translate
              }}</span>
          </label>
        </div>
        <ng-container *ngIf="(topUpCalcForm.value.isIncreaseByPercent); else byAmountField">
          <div class="selected-range" (click)="autoFocus($event, 'increaseAmount')">
            <input aria-labelledby="increasePercentBy" type="number" [attr.min]="getInputLimit(sliderKeyName.increasePercent, false)"
                   [attr.max]="getInputLimit(sliderKeyName.increasePercent, true)"
                   formControlName="increasePercent" [(ngModel)]="topUpCalcForm.value.increasePercent"
                   (change)="onCalculatorChange($event, 'increasePercent')" class="calc-input text-end"
                   style="max-width: 30px;" id="increaseAmount">
            <span class="calc-input-prefix">{{ "ftiCalc.calculator-percentage-symbol" | translate }}</span>
          </div>
        </ng-container>
        <ng-template #byAmountField>
          <div class="selected-range">
            <span class="calc-input-prefix">{{
                    "ftiCalc.calculator-indian-rupee-symbol" | translate
              }}</span>
            <input type="number" aria-labelledby="increasePercentBy" style="width: 100%;" [attr.min]="getInputLimit(sliderKeyName.increaseAmount, false)"
                   [attr.max]="getInputLimit(sliderKeyName.increaseAmount, true)"
                   formControlName="increaseAmount" [(ngModel)]="topUpCalcForm.value.increaseAmount"
                   (change)="onCalculatorChange($event, 'increaseAmount')" class="calc-input text-start">
          </div>
        </ng-template>
      </div>
    </label>

    <ng-container *ngIf="topUpCalcForm.value.isIncreaseByPercent; else byAmountSlider">
      <div class="range-slider">
        <input aria-labelledby="increasePercentBy" type="range" formControlName="increasePercent"
               [value]="topUpCalcForm.value.increasePercent || getInputLimit(sliderKeyName.increasePercent)"
               (change)="onCalculatorChange($event, 'increasePercent')" class="custom-range"
               [attr.min]="getInputLimit(sliderKeyName.increasePercent, false)"
               [attr.max]="getInputLimit(sliderKeyName.increasePercent, true)"
               [style.background]="increasePercentSliderStyle">
      </div>
      <div class="d-flex text-small justify-content-between">
        <span>{{ "ftiCalc.calculator-zero-percent" | translate }}</span>
        <span>{{ "ftiCalc.calculator-hundred-percent" | translate }}</span>
      </div>
    </ng-container>

    <ng-template #byAmountSlider>
      <div class="range-slider">
        <input type="range" aria-labelledby="increasePercentBy" formControlName="increaseAmount"
               [value]="topUpCalcForm.value.increaseAmount || getInputLimit(sliderKeyName.increaseAmount)"
               (change)="onCalculatorChange($event, 'increaseAmount')" class="custom-range"
               [attr.min]="getInputLimit(sliderKeyName.increaseAmount, false)"
               [attr.max]="getInputLimit(sliderKeyName.increaseAmount, true)"
               [style.background]="increaseAmountSliderStyle">
      </div>
      <div class="d-flex text-small justify-content-between">
        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                "ftiCalc.calculator-500" | translate
          }}</span>
        <span>{{ "ftiCalc.calculator-indian-rupee-symbol" | translate }}{{
                "ftiCalc.calculator-10L" | translate
          }}</span>
      </div>
    </ng-template>

  </div>


  <!-- 4. Period -->
  <div class="form-group ">
    <label id="overAPeriodOF" for="customRange2" class="form-label">

      {{ "ftiCalc.calculator-year" | translate }}

      <div class="selected-range" (click)="autoFocus($event, 'period')">

        <input type="number" aria-labelledby="overAPeriodOf" [attr.min]="getInputLimit(sliderKeyName.period, false)"
               [attr.max]="getInputLimit(sliderKeyName.period, true)" formControlName="period"
               [(ngModel)]="topUpCalcForm.value.period" (change)="onCalculatorChange($event, 'period')"
               class="calc-input text-end forchangecustomcalculator" id="period">

        <span class="calc-input-prefix">{{ "ftiCalc.calculator-years" | translate }}</span>
      </div>
    </label>
    <div class="range-slider">

      <input type="range" aria-labelledby="overAPeriodOf" formControlName="period"
             [value]="topUpCalcForm.value.period || getInputLimit(sliderKeyName.period)"
             (change)="onCalculatorChange($event, 'period')" class="custom-range"
             [attr.min]="getInputLimit(sliderKeyName.period, false)"
             [attr.max]="getInputLimit(sliderKeyName.period, true)" [style.background]="periodSliderStyle">

    </div>
    <div class="d-flex text-small justify-content-between">
      <span> {{ getInputLimit(sliderKeyName.period, false) }} {{
              "ftiCalc.calculator-single-year" | translate
        }}</span>
      <span>{{ getInputLimit(sliderKeyName.period, true) }} {{
              "ftiCalc.calculator-years" | translate
        }}</span>
    </div>
  </div>

  <!-- 5. ROI -->
  <div class="form-group ">
    <label id="expectedRateOfReturn" for="customRange2" class="form-label">{{ "ftiCalc.calculator-rate-of-return" | translate }}
      <div class="selected-range" (click)="autoFocus($event, 'expectedReturn')">
        <input aria-labelledby="expectedRateOfReturn" type="number" [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
               [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)" formControlName="expectedReturn"
               [(ngModel)]="topUpCalcForm.value.expectedReturn"
               (change)="onCalculatorChange($event, 'expectedReturn')"
               class="calc-input text-end forchangecustomcalculator" id="expectedReturn">
        <span class="calc-input-prefix"> {{ "ftiCalc.calculator-percentage-symbol" | translate }}</span>
      </div>
    </label>
    <div class="range-slider">
      <input aria-labelledby="expectedRateOfReturn" type="range" formControlName="expectedReturn"
             [value]="topUpCalcForm.value.expectedReturn || getInputLimit(sliderKeyName.expectedReturn)"
             (change)="onCalculatorChange($event, 'expectedReturn')" class="custom-range"
             [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
             [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)"
             [style.background]="expectedReturnSliderStyle">
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, false) }}{{
              "ftiCalc.calculator-percentage-symbol" |
                  translate
        }}</span>
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, true) }}{{
              "ftiCalc.calculator-percentage-symbol" |
                  translate
        }}</span>
    </div>
  </div>
</form>
