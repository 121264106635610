<form [formGroup]="sipCalcForm" *ngIf="sipCalcForm">

  <!-- Earn Amount -->
  <div class="form-group" *ngIf="handleFieldVisibility('earn')">
    <label for="earning" class="form-label">{{ "ftiCalc.calculator-sip-want-earn" | translate }}
      <div class="selected-range">
        <span class="calc-input-prefix" style="min-width: 10px;">₹</span>
        <input id="earning" name="earn" type="number" (change)="onCalculatorChange($event, 'earn')"
               [(ngModel)]="sipCalcForm.value.earn" formControlName="earn"
               [attr.min]="getInputLimit(sliderKeyName.earn, false)"
               [attr.max]="getInputLimit(sliderKeyName.earn, true)" class="calc-input amount text-start">
      </div>

    </label>
    <div class="range-slider">
      <input type="range" (change)="onCalculatorChange($event, 'earn')"
             [value]="sipCalcForm.value.earn || getInputLimit(sliderKeyName.earn)" formControlName="earn"
             class="custom-range" [attr.min]="getInputLimit(sliderKeyName.earn, false)"
             [attr.max]="getInputLimit(sliderKeyName.earn, true)" [style.background]="earnSliderStyle">
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>₹{{ "ftiCalc.calculator-50L" | translate }}</span>
      <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
    </div>
  </div>

  <!-- Duration | Frequency -->
  <div class="form-group">
    <label id="iWantToInvest" for="amount" class="form-label" *ngIf="handleFieldVisibility('amount')">
      <span>{{ "ftiCalc.calculator-want-invest" | translate }}</span>

      <div class="selected-range" (click)="autoFocus($event, 'amount')">
        <span class="calc-input-prefix">₹</span>
        <input type="number" [attr.min]="getInputLimit(sliderKeyName.amount, false)"
               [attr.max]="getInputLimit(sliderKeyName.amount, true)" [(ngModel)]="sipCalcForm.value.amount"
               formControlName="amount" (change)="onCalculatorChange($event, 'amount')"
               class="calc-input amount text-start" id="amount">

      </div>
    </label>

    <!-- Duration | Frequency -->
    <div *ngIf="selectedSipType === 'amount'" style="margin-bottom: 23px;">
      <label for="Daily" class="form-label"> Frequency </label>
    </div>
    <div class="invest-options" *ngIf="handleFieldVisibility('time')">
      <div class="form-check">
        <input formControlName="time" value="DAILY" class="form-check-input" type="radio" name="time" id="Daily"
               (change)="onCalculatorChange($event, 'time')">
        <label class="form-check-label" for="Daily">
          {{ "ftiCalc.calculator-lumpsum-text2" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time" value="WEEKLY" class="form-check-input" type="radio" name="time"
               id="Weekly" (change)="onCalculatorChange($event, 'time')" checked>
        <label class="form-check-label" for="Weekly">
          {{ "ftiCalc.calculator-lumpsum-text3" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time" value="MONTHLY" class="form-check-input" type="radio" name="time"
               id="Monthly" (change)="onCalculatorChange($event, 'time')">
        <label class="form-check-label" for="Monthly">
          {{ "ftiCalc.calculator-lumpsum-text4" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time" value="QUARTERLY" class="form-check-input" type="radio" name="time"
               id="Quarterly" (change)="onCalculatorChange($event, 'time')">
        <label class="form-check-label" for="Quarterly">
          {{ "ftiCalc.calculator-quarterly-text1" | translate }}
        </label>
      </div>
    </div>

    <div class="range-slider" *ngIf="handleFieldVisibility('amount')">
      <input type="range" [attr.min]="getInputLimit(sliderKeyName.amount, false)"
             [attr.max]="getInputLimit(sliderKeyName.amount, true)"
             [value]="sipCalcForm.value.amount || getInputLimit(sliderKeyName.amount)" formControlName="amount"
             (change)="onCalculatorChange($event, 'amount')" class="custom-range"
             [style.background]="amountSliderStyle"
             aria-labelledby="iWantToInvest">

    </div>
    <div class="d-flex text-small justify-content-between" *ngIf="handleFieldVisibility('amount')">
      <span>₹{{ "ftiCalc.calculator-500" | translate }}</span>
      <span>₹{{ "ftiCalc.calculator-10L" | translate }}</span>
    </div>
  </div>

  <!-- Period -->
  <div class="form-group" *ngIf="handleFieldVisibility('period')">
    <label id="overAPeriodOf" for="period" class="form-label">
      <span *ngIf="handleFieldVisibility('earn'); else overaYear">
        {{ 'ftiCalc.calculator-after-period' | translate }}
      </span>
      <ng-template #overaYear>
        {{ 'ftiCalc.calculator-year' | translate }}
      </ng-template>
      <div class="selected-range" (click)="autoFocus($event, 'period')">
        <input type="number" [attr.min]="getInputLimit(sliderKeyName.period, false)"
               [attr.max]="getInputLimit(sliderKeyName.period, true)" formControlName="period"
               [(ngModel)]="sipCalcForm.value.period" (change)="onCalculatorChange($event, 'period')"
               class="calc-input text-end forchangecustomcalculator" id="period">

        <span class="calc-input-prefix">{{ "ftiCalc.calculator-years" | translate }}</span>
      </div>
    </label>
    <div class="range-slider">
      <input type="range" formControlName="period"
             [value]="sipCalcForm.value.period || getInputLimit(sliderKeyName.period)"
             (change)="onCalculatorChange($event, 'period')" class="custom-range"
             [attr.min]="getInputLimit(sliderKeyName.period, false)"
             [attr.max]="getInputLimit(sliderKeyName.period, true)" [style.background]="periodSliderStyle"
             aria-labelledby="overAPeriodOf">
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ "ftiCalc.calculator-one-year" | translate }}</span>
      <span>{{ "ftiCalc.calculator-sixty-years" | translate }}</span>
    </div>
  </div>

  <!-- Returns -->
  <div class="form-group" *ngIf="handleFieldVisibility('expectedReturn')">
    <label id="ExpectedROR" for="expectedReturn" class="form-label">{{ "ftiCalc.calculator-rate-of-return" | translate }}
      <div class="selected-range" (click)="autoFocus($event, 'expectedReturn')">
        <input type="number" [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
               [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)" formControlName="expectedReturn"
               [value]="sipCalcForm.value.expectedReturn || getInputLimit(sliderKeyName.expectedReturn)"
               (change)="onCalculatorChange($event, 'expectedReturn')"
               class="calc-input text-end  forchangecustomcalculator" id="expectedReturn">

        <span class="calc-input-prefix"> %</span>
      </div>
    </label>
    <div class="range-slider">
      <input #expectedReturnSlider type="range" formControlName="expectedReturn"
             [value]="sipCalcForm.value.expectedReturn || getInputLimit(sliderKeyName.expectedReturn)"
             (change)="onCalculatorChange($event, 'expectedReturn')" class="custom-range"
             [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
             [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)"
             [style.background]="expectedReturnSliderStyle"
             aria-labelledby="ExpectedROR">

    </div>
    <div class="d-flex text-small justify-content-between">
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, false) }}%</span>
      <span>{{ getInputLimit(sliderKeyName.expectedReturn, true) }}%</span>
    </div>
  </div>
</form>
