export class FundReportPageCategory {
  public static formsInstructions = 'Forms & Instructions';
  public static fundDocuments = 'Fund Documents';
  public static fundLiterature = 'Fund Literature';
  public static disclosureDocument = 'Disclosure Document';
  public static updates = 'Updates';
  public static informationRatio = 'Information Ratio';

  public static formsInstructionsValue = 'FORMS-AND-INSRTUCTIONS';
  public static fundDocumentsValue = 'FUND-DOCUMENT';
  public static fundLiteratureValue = 'FUND-LITERATURE';
  public static disclosureDocumentValue = 'DISCLOSURE-DOCUMENT';
  public static updatesValue = 'UPDATES';
  public static reportsValue = 'REPORTS';
  public static informationRatioValue = 'INFORMATION-RATIO';

  public static report = 'report';
  public static download = 'download';
}
