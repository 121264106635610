import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CalculatorService } from "@services/calculator.service";
import { Subscription } from "rxjs";
import { ChartService } from "../ind-calculators.service";
import { WINDOW } from "@ng-web-apis/common";
import { TranslateService } from "@components/shared/translate/translate.service";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import * as Highcharts from "highcharts";
import { Logger } from "@utils/logger";
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";

const logger = Logger.getLogger("CagrCalculatorComponent");

@Component({
  selector: "ft-cagr-calculator",
  templateUrl: "./cagr-calculator.component.html",
  styleUrls: ["./cagr-calculator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CagrCalculatorComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  isGraphView: boolean = true;
  label1: string;
  label2: string;
  private chartOptionsSubscription: Subscription;

  cagrCalcForm!: FormGroup;
  calculatedData: any;

  sliderKeyName: any = {
    initAmount: "initAmount",
    resultantAmt: "resultantAmt",
    period: "period",
  };

  initAmountSliderStyle: any =
    "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  resultantAmtSliderStyle: any =
    "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";
  periodSliderStyle: any = "linear-gradient(90deg, #3769ff 0%, #e0e0e07a 0%)";

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private calcService: CalculatorService,
    private chartService: ChartService,
    private cd: ChangeDetectorRef,
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngAfterViewInit(): void {
    this.handleAllSliderFill();
    this.cd.detectChanges();
  }

  setIsGraphView(v: any) {
    this.isGraphView = v;
  }

  ngOnInit(): void {
    this.label1 = this.translateService.instant(
      "ftiCalc.calculator-invest-amount"
    );
    this.label2 = this.translateService.instant(
      "ftiCalc.calculator-cagr-gains"
    );

    this.chartOptionsSubscription = this.chartService
      .getChartOptionsObservable()
      .subscribe((chartOptions) => {
        this.renderChart(chartOptions);
      });
    this.chartService.initializeChartOptions({series: []});
    this.initForm();

    // slider track color handle on form value change
    this.cagrCalcForm.valueChanges.subscribe((v) => {
      this.handleAllSliderFill();
    });
    this.onCalculatorChange();
  }

  // Form Init
  initForm = () => {
    this.cagrCalcForm = this.fb.group({
      [this.sliderKeyName.initAmount]: [10000],
      [this.sliderKeyName.resultantAmt]: [50000000],
      [this.sliderKeyName.period]: [10],
    });
  };

  // set all slider according to their values.
  handleAllSliderFill() {
    if (!this.cagrCalcForm) {
      return;
    }
    for (let keyName in this.sliderKeyName) {
      let v = this.cagrCalcForm.value[keyName];
      // handle null value as it gives error on slide
      if (v == undefined) {
        v = this.getInputLimit(keyName);
      }
      this.applyFillSlider(keyName, v);
    }
  }

  // Handle slide track color
  applyFillSlider = (type: any, value: any = null, slider: any = null) => {
    let element = slider;
    if (slider && slider.hasOwnProperty("first")) {
      element = slider.first.nativeElement;
    } else if (slider && slider.hasOwnProperty("nativeElement")) {
      element = slider.nativeElement;
    }

    let currentValue = value || element?.value || 0;

    const settings = {
      fill: "#3769ff",
      background: "#e0e0e07a",
    };

    let minValue: any = this.getInputLimit(type, false);
    let maxValue: any = this.getInputLimit(type, true);

    let percentage = ((currentValue - minValue) * 100) / (maxValue - minValue);
    if (
      !isNaN(Number(currentValue)) &&
      Number(currentValue) > Number(maxValue)
    ) {
      percentage = 100;
    } else if (currentValue <= 0) {
      percentage = 0;
    }

    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
      settings.background
    } ${percentage + 0.1}%) !important`;

    switch (type) {
      case this.sliderKeyName.initAmount:
        this.initAmountSliderStyle = bg;
        break;
      case this.sliderKeyName.resultantAmt:
        this.resultantAmtSliderStyle = bg;
        break;
      case this.sliderKeyName.period:
        this.periodSliderStyle = bg;
        break;
    }
  };

  // Get input field min & max limit
  getInputLimit = (type: any, isMax: any = false) => {
    let v;

    const minInitAmount = 1e4;
    const maxInitAmount = 1e8;

    const minResultantAmt = 1e4;
    const maxResultantAmt = 1e8;

    const minPeriod = 1;
    const maxPeriod = 60;

    switch (type) {
      case this.sliderKeyName.initAmount:
        v = isMax ? maxInitAmount : minInitAmount;
        break;
      case this.sliderKeyName.resultantAmt:
        v = isMax ? maxResultantAmt : minResultantAmt;
        break;
      case this.sliderKeyName.period:
        v = isMax ? maxPeriod : minPeriod;
        break;
    }

    return v;
  };

  // Get the Graph latest Object
  setGraphValues(data: any) {
    const {labels, totalValueData, investedData} =
      this.chartService.processData(data);
    this.chartService.updateChart({
      xAxis: {
        categories: labels,
        title: {
          text: 'Investment Period',
          style: {
            color: '#3C425D',
            fontWeight: 'bold',
            fontSize: '14px',
            fontFamily: 'TTCommonsProRegular, sans-serif'
          }
        },
      },
      series: [
        {
          name: this.label1,
          data: totalValueData,
          type: "line",
        },
        {
          name: this.label2,
          data: investedData,
          type: "line",
        },
      ],
    });
  }

  // On Calculation params change
  onCalculatorChange = (e: any = null, type: string = "") => {
    let value: any = e?.target?.value || null;
    const maxLimit = this.getInputLimit(type, true);
    const minLimit = this.getInputLimit(type, false);

    if (
      type &&
      maxLimit != undefined &&
      minLimit != undefined &&
      Object.values(this.sliderKeyName).includes(type)
    ) {
      e.preventDefault();
      let v = value;
      if (Number(value) > maxLimit) {
        v = maxLimit;
      } else if (Number(value) < minLimit) {
        v = minLimit;
      }
      this.cagrCalcForm.controls[type].patchValue(v);
    }

    if (!this.cagrCalcForm || !this.cagrCalcForm.value) {
      return;
    }
    const {initAmount, resultantAmt, period} = this.cagrCalcForm.value;

    // Clear previous calculated Data
    this.calculatedData = {};
    this.setGraphValues({});

    if (!initAmount || !period) {
      return;
    }

    const finalData = this.calcService.get_cagr(
      initAmount,
      resultantAmt,
      period
    );
    this.calculatedData = finalData;
    this.setGraphValues(finalData);
  };

  private renderChart(chartOptions: Highcharts.Options): void {
    Highcharts.chart("highcharts-container-cagr", chartOptions);
  }

  autoFocus(event: any, refId: any) {
    const divId = document.getElementById(refId);
    divId.focus();
  }

  investNow(fund) {
    this.analyticsService.trackEvent({
      event: 'calculator Page',
      category: 'Fund Explorer',
      action: 'Invest Now',
      label: 'InvestNow Sticky',
    });
    this.investNowService.investNow('');
  }

  ngOnDestroy(): void {
    if (this.chartOptionsSubscription) {
      this.chartOptionsSubscription.unsubscribe();
    }
  }
}
